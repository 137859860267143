<template>
  <div id="admin-comment">
    <a-table
      :loading="loading"
      :columns="columns" 
      :data-source="comments.data" 
      :pagination="false">
      <template #action="{ text, index }">
         <a-button @click="openEdit(text.reply, text.id, index)" style="margin-right: 15px;" type="primary"><EditOutlined /></a-button>
         <a-button @click="isDelCom(text.id, index)" style="margin-right: 15px;" type="danger"><DeleteOutlined /></a-button>
      </template>
    </a-table>
    <div style="margin: 20px auto;text-align: center;">
      <a-pagination 
        v-model:current="pagination.current" 
        :total="pagination.total" 
        v-model:pageSize="pagination.pagesize"
        show-less-items 
        @change="changePage"
      />
    </div>
    <template>
      <a-modal
        title="回复评论"
        cancelText="取消"
        okText="确定"
        v-model:visible="modal.visible"
        :confirm-loading="modal.confirmLoading"
        @ok="Ok"
      >
        <a-input v-model:value="reply" placeholder="回复该评论！" />
      </a-modal>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive, createVNode } from "vue";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue"
import { useRoute } from "vue-router";
import { getArtComment, replyArtComment, delArtComment, getLeavingMsg, ReplyLeavingMsg, delLeavingMsg } from "@/api"
import { message, Modal } from 'ant-design-vue';

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined
  },
  props: {
    sign: Number
  },
  setup(porps) {
    const columns = reactive([
      { title: 'ID', width: 100, dataIndex: 'id', key: 'id' },
      { title: '用户名', width: 200, dataIndex: 'username', key: 'username' },
      { title: '内容', width: 300, dataIndex: 'content', key: 'content' },
      { title: '回复', width: 300, dataIndex: 'reply', key: 'reply' },
      { title: '创建时间', dataIndex: 'time', key: 'time' },
      {
        title: '操作',
        key: 'operation',
        width: 160,
        slots: { customRender: 'action' },
      },
    ]);
    const loading = ref(false);
    const route = useRoute()
    const comments = reactive({data: null});
    const modal = reactive({
      visible: false,
      confirmLoading: false
    })
    const reply = ref('');
    const id = ref(0);
    let index: number;
    // 获取评论参数
    const articleParams = {
      artid: "0",
      pagenum: 1,
      pagesize: 25
    }
    // 分页参数
    const pagination = ref({
      current: 1,
      total: 10,
      pagesize: articleParams.pagesize
    })

    // 分页
    const changePage = (page: number) => {
      articleParams.pagenum = page;
      getArtComment(articleParams).then((res: any) => {
        comments.data = res.data.data.comments
        window.scrollTo(0, 0);
      })
    }
    // 删除
    const delComFn = (res: any) => {
      if (res.code == 200) {
        modal.visible = false;
        (comments as any).data.splice(index, 1);
        return message.success("删除成功");
      }
      message.error("删除失败！");
    }
    const isDelCom = (uid: number, idx: number) => {
      id.value = uid;
      index = idx;
      Modal.confirm({
        title: '是否删除该评论',
        icon: createVNode(ExclamationCircleOutlined),
        content: '该操作不可恢复，请谨慎执行！',
        cancelText: "取消",
        okText: "确定",
        onOk() {
          if (porps.sign == 1) {
            delLeavingMsg({id: id.value}).then((res: any) => {
              delComFn(res)
            })
          } else {
            delArtComment({id: id.value}).then((res: any) => {
              delComFn(res)
            })
          }
        }
      });
    }
    const openEdit = (text: string, uid: number, idx: number) => {
      modal.visible = true;
      reply.value = text;
      id.value = uid;
      index = idx;
    }
    // 回复
    const replyFn = (res: any) => {
      modal.confirmLoading = false;
      if (res.code == 200) {
        modal.visible = false;
        (comments as any).data[index].reply = reply.value
        return message.success("回复成功！");
      }
      message.error("回复失败！");
    }
    const Ok = () => {
      modal.confirmLoading = true;
      loading.value = true;
      if (porps.sign == 1) {
        ReplyLeavingMsg({id: id.value, reply: reply.value}).then((res: any) => {
          replyFn(res);
          loading.value = false;
        })
      } else {
        replyArtComment({id: id.value, reply: reply.value}).then((res: any) => {
          replyFn(res);
          loading.value = false;
        })
      }
    }
    onMounted(() => {
      loading.value = true;
      if (porps.sign == 1) {
        getLeavingMsg(articleParams).then((res: any) => {
          comments.data = res.data.data.messages;
          pagination.value.total = res.data.data.total;
          loading.value = false;
        })
      } else {
        articleParams.artid = route.query.id as string;
        getArtComment(articleParams).then((res: any) => {
          comments.data = res.data.data.comments;
          pagination.value.total = res.data.data.total;
          loading.value = false;
        })
      }
    })
    return {
      comments,
      loading,
      columns,
      pagination,
      modal,
      reply,
      changePage,
      isDelCom,
      Ok,
      openEdit
    }
  }
})
</script>

<style lang="scss">

</style>